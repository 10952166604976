import { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

function OrgLink({ children, to, className, style, target = "", label }) {
    const params = useParams();
    const orgURL = params.org
        ? `/${params.org}${to[0] === "/" ? "" : "/"}${to}`
        : to;
    const linkRef = useRef(null);

    const overridePrefixes = ["https", "mailto"];

    function shouldOverride(dest) {
        return overridePrefixes.some((prefix) => dest.includes(prefix));
    }

    useEffect(() => {
        if (!children?.type) {
            linkRef.current.setAttribute(
                "aria-label",
                `${children}: link to ${to.slice(1).split("-").join(" ")} page`
            );
        }
    });

    return (
        <Link
            ref={linkRef}
            to={shouldOverride(to) ? to : orgURL}
            className={className}
            style={style}
            target={target}
            aria-label={label}
            rel="noreferrer"
        >
            {children}
        </Link>
    );
}

export default OrgLink;
