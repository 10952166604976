import "../css/ProgramCard.css";
import OrgLink from "./OrgLink";

function ProgramCard({
    title,
    checks,
    buttonText,
    url,
    target = "",
    outlineButton = false,
    orangeButton = false,
    bigShadow = false,
    children,
}) {
    return (
        <div className={`program-card ${bigShadow ? "big-shadow" : ""}`}>
            <h2 className="heading">{title}</h2>
            <div className="separator"></div>
            <div className="description-content">{children}</div>
            <div className="separator"></div>
            <div className="checks-container">
                {checks.map((check) => (
                    <div key={check} className="check">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="10"
                                fill="#F3F5FB"
                            ></rect>
                            <path
                                d="M10.9003 14.118L17.3999 8L18.4004 8.94102L10.9003 16L6.40039 11.7647L7.40021 10.8237L10.9003 14.118Z"
                                fill="#160042"
                            ></path>
                        </svg>
                        <span>{check}</span>
                    </div>
                ))}
                <OrgLink
                    to={url}
                    className={`choice-button ${
                        outlineButton ? "outline" : ""
                    } ${orangeButton ? "orange" : ""}`}
                    target={target}
                >
                    {buttonText}
                </OrgLink>
            </div>
        </div>
    );
}

export default ProgramCard;
